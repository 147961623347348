<template>
  <v-app>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-1"
              id="kt_wizard_v1"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
              >
                <div class="col-xl-12">
                  <!--begin: Wizard Form-->
                  <form class="form" @submit.stop.prevent="formOnsubmit()">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h3
                        class="
                          font-weight-bolder
                          text-dark
                          font-size-h4 font-size-h1-lg
                        "
                      >
                        Tambah Tahun Ajaran
                      </h3>
                      <b-form-group
                        id="input-group-name"
                        label="Nama:"
                        label-for="input-name"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="form.name"
                          placeholder="Nama Tahun Ajaran"
                        ></b-form-input>
                        <small class="text-danger">{{ error.name }}</small>
                      </b-form-group>

                      <b-form-group
                        id="input-group-start-periode"
                        label="Awal Periode:"
                        label-for="input-start-periode"
                      >
                        <v-dialog
                          ref="dialog"
                          v-model="modal_start_periode"
                          :return-value.sync="form.start_periode"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              id="input-trigger-modal-start-periode"
                              v-model="form.start_periode"
                              placeholder="Awal Periode"
                            >
                            </b-form-input>
                          </template>

                          <v-date-picker
                            v-if="modal_start_periode"
                            v-model="form.start_periode"
                            locale="id"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal_start_periode = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(form.start_periode)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </b-form-group>
                      <b-form-group
                        id="input-group-end-periode"
                        label="Akhir Periode:"
                        label-for="input-end-periode"
                      >
                        <v-dialog
                          ref="dialogend"
                          v-model="modal_end_periode"
                          :return-value.sync="form.end_periode"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              id="input-trigger-modal-end-periode"
                              v-model="form.end_periode"
                              placeholder="Akhir Periode"
                            >
                            </b-form-input>
                          </template>

                          <v-date-picker
                            v-if="modal_end_periode"
                            v-model="form.end_periode"
                            locale="id"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal_end_periode = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogend.save(form.end_periode)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </b-form-group>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button type="submit" variant="primary"
                          >Simpan</b-button
                        >
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="default"
                          @click="$router.push('/masters/school-years')"
                        >
                          Batal
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                  <!--end: Wizard Form-->
                </div>
              </div>
              <!--end: Wizard Body-->
            </div>
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        start_periode: new Date().toISOString().substr(0, 10),
        end_periode: new Date().toISOString().substr(0, 10),
        periode_type_id: "",
      },
      error: {
        name: "",
        start_periode: "",
        end_periode: "",
        periode_type_id: "",
      },
      modal_start_periode: false,
      modal_end_periode: false,
      periode_types: [],
    };
  },
  methods: {
    async getPeriodeTypeOption() {
      let response = await module.setTreeSelect("api/periode-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.periode_types = response.data;
        this.periode_types.unshift({
          label: "Pilih Jenis Periode",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, "api/school-years");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/school-years");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Tahun Ajaran", route: "/masters/school-years" },
      { title: "Tambah Tahun Ajaran" },
    ]);

    this.getPeriodeTypeOption();
  },
};
</script>